import * as _ from 'lodash'
import * as $ from 'jquery'

const il_count = () => $('.recipe-ingredients').length
const section_count = () => $('.recipe-instructions').length

const remove_il = (index: number) => {
  $('.recipe-ingredients').slice(index, index + 1).remove()
  update_il()
}

const remove = (index: number) => {
  $('.recipe-instructions').slice(index, index + 1).remove()
  update()
}

const update_il = () => {
  update_elements_il()
  update_attrs_il()
}

const update = () => {
  update_elements()
  update_attrs()
  disable_trix_upload()
}

const disable_trix_upload = () => {
  $('trix-editor').off('trix-file-accept')
  $('trix-editor').on('trix-file-accept', e => {
    e.preventDefault()
  })
  $('span.trix-button-group--file-tools').hide()
}

const update_elements = () => {
  if (section_count() === 1) {
    $('.recipe-instructions .name.field').remove()
  } else {
    $('.recipe-instructions').not($('.recipe-instructions').has('.name.field'))
      .prepend(`
        <div class="required name field">
          <label>Section Name</label>
          <div class="removable">
            <input type="text" name="${name}">
          </div>
        </div>
      `)

    $('.recipe-instructions .name.field .removable')
      .not($('.removable .remove').parent())
      .append(`
        <button class="remove">Remove Section</button>
      `)
  }
}

const update_attrs = () => {
  $('.recipe-instructions .remove').each((index, elem) => {
    $(elem).off('click')
    $(elem).click(ev => {
      ev.preventDefault()
      remove(index)
    })
  })

  $('.recipe-instructions .id').each((index, elem) => {
    const name = `recipe[sections_attributes][${index}][id]`
    $(elem).attr('name', name)
  })

  $('.recipe-instructions .name input').each((index, elem) => {
    const name = `recipe[sections_attributes][${index}][name]`
    $(elem).attr('name', name)
  })

  const blobs = $('.recipe-instructions .body trix-editor')
    .map((index, elem) => ({ id: $(elem).attr('input'), index })).get()

  _.forEach(blobs, blob => {
    const { id, index } = blob
    const name = `recipe[sections_attributes][${index}][body]`
    $(`#${id}`).attr('name', name)
  })
}

const update_elements_il = () => {
  if (il_count() === 1) {
    $('.recipe-ingredients .name.field').remove()
  } else {
    $('.recipe-ingredients').not($('.recipe-ingredients').has('.name.field'))
      .prepend(`
        <div class="required name field">
          <label>List Name</label>
          <div class="removable">
            <input type="text" name="${name}">
          </div>
        </div>
      `)

    $('.recipe-ingredients .name.field .removable')
      .not($('.removable .remove').parent())
      .append(`
        <button class="remove">Remove List</button>
      `)
  }
}

const update_attrs_il = () => {
  $('.recipe-ingredients .remove').each((index, elem) => {
    $(elem).off('click')
    $(elem).click(ev => {
      ev.preventDefault()
      remove_il(index)
    })
  })

  $('.recipe-ingredients .id').each((index, elem) => {
    const name = `recipe[ingredient_lists_attributes][${index}][id]`
    $(elem).attr('name', name)
  })

  $('.recipe-ingredients .name input').each((index, elem) => {
    const name = `recipe[ingredient_lists_attributes][${index}][name]`
    $(elem).attr('name', name)
  })

  $('.recipe-ingredients .ingredients textarea').each((index, elem) => {
    const name = `recipe[ingredient_lists_attributes][${index}][ingredients]`
    $(elem).attr('name', name)
  })
}

const init = () => {
  $('.recipe-ingredients-add').off('click')
  $('.recipe-ingredients-add').click(function(event) {
    event.preventDefault()

    /* Add a new root .recipe-ingredients */
    $(this).before('<div class="recipe-ingredients"></div>')
    const ingredients = $('.recipe-ingredients').last()

    /* Add an input */
    $(ingredients).append(`
      <div class="required ingredients field">
        <textarea
          name="recipes[ingredient_lists_attributes][9999][ingredients]"
          rows="8"></textarea>
      </div>
    `)

    update_il()
  })

  $('.recipe-instructions-add').off('click')
  $('.recipe-instructions-add').click(function(event) {
    event.preventDefault()

    /* Add a new root .recipe-instructions */
    $(this).before('<div class="recipe-instructions"></div>')
    const instructions = $('.recipe-instructions').last()

    /* Add an input and trix editor */
    const rand = _.random(0, 9001) // It's over 9000!
    const id = `recipe_sections_attributes_${rand}_body`

    $(instructions).append(`
      <div class="field body">
        <input type="hidden" id="${id}" value="">
        <trix-editor class="trix-content" input="${id}"></trix-editor>
      </div>
    `)

    update()
  })

  update()
  update_il()
}

$(document).ready(function() {
  init()
})

$(document).on('turbolinks:load', function() {
  init()
})
