import * as $ from 'jquery'
import { Controller } from 'stimulus'
import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import max from 'lodash/max'
import without from 'lodash/without'

/**
 * Manages tag field state.
 */
export default class extends Controller {
  static values = { allowCreate: Boolean, hiddenName: String, tags: Array }

  connect() {
    this.setHiddenFields()
    this.abort = new AbortController()
    this.fetchResults = debounce(this._fetchResults.bind(this), 300)
    $(this.element).find('input[type=text]').css('width', `${max([this.searchValue().length + 3, 8])}ex`)
  }

  _fetchResults(query) {
    let signal = this.abort.signal
    let url = '/tags.json'
    if (query) url += `?q=${query}`

    const self = this
    fetch(url, { signal }).then(resp => {
      return resp.json()
    }).then(tags => {
      const resultsElem = $(self.element).find('.results')
      resultsElem.find('.result').remove()
      tags.forEach(tag => {
        $(
          `<a href="#" class="result" data-tag="${tag}">
            ${tag}
          </a>`
        ).appendTo(resultsElem).on('mousedown', event => {
          const newTag = $(event.target).data('tag')

          if (includes(self.tagsValue, newTag)) {
            return
          }

          const tmp = self.tagsValue.concat([newTag])
          tmp.sort()
          self.tagsValue = tmp
          self.setHiddenFields()
          $(this.element).find('input[type=text]').first().val('')
        })
      })
    }).catch(err => {
      $(self.element).find('.results .result').remove()
      $(self.element).find('.results').append(
        `<a href="#" class="result">
          None
        </a>`
      )
    })
  }

  searchValue() {
    return $(this.element).find('input[type=text]').first().val()
  }

  onChange() {
    const val = this.searchValue()
    this.fetchResults(val)
    $(this.element).find('input[type=text]').css('width', `${max([val.length + 3, 8])}ex`)
  }

  activate() {
    $(this.element).addClass('active')

    $(
      `<div class="results">
        <a href="#" class="result">
          Loading...
        </a>
      </div>`
    ).appendTo($(this.element))

    this.fetchResults(this.searchValue())
  }

  deactivate() {
    this.fetchResults.cancel()

    $(this.element).find('.results').remove()
    $(this.element).removeClass('active')
  }

  createIfNeeded(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      const newTag = this.searchValue()

      if (!newTag || includes(this.tagsValue, newTag)) {
        return
      }

      const tmp = this.tagsValue.concat([newTag])
      tmp.sort()
      this.tagsValue = tmp
      this.setHiddenFields()
      $(this.element).find('input[type=text]').first().val('')
    }
  }

  setHiddenFields() {
    $(this.element).find('input[type=hidden]').remove()
    $(this.element).find('span.tag').remove()
    const self = this

    const tmp = this.tagsValue.slice()
    tmp.reverse()
    tmp.forEach(val => {
      $(this.element).prepend(`<input name="${self.hiddenNameValue}" type="hidden" value="${val}" />`)
    })

    const textInput = $(this.element).find('input[type=text]')
    tmp.reverse()
    tmp.forEach(val => {
      textInput.before(
        `<span class="tag">
          ${val}
          <a class="remove" data-tag="${val}" data-action="click->tag-field#removeTag">&times;</a>
        </span>`
      )
    })
  }

  focus() {
    const elem = $(this.element).find('input[type=text]').first().get()[0]
    if (elem) {
      elem.focus()
    }
  }

  removeTag(event) {
    event.preventDefault()

    const toRemove = $(event.target).data('tag')

    const tmp = without(this.tagsValue, toRemove)
    tmp.sort()
    this.tagsValue = tmp
    this.setHiddenFields()
  }
}
