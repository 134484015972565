import * as $ from 'jquery'

const init = () => {
  $('#mobile-menu-button').off('click')
  $('#mobile-menu-button').on('click', ev => {
    ev.preventDefault()
    $('#mobile-menu').addClass('open')

    $('#mobile-menu .background').off('click')
    $('#mobile-menu .background').on('click', () => {
      $('#mobile-menu').removeClass('open')
    })

    $('#mobile-menu a').on('click', () => {
      $('#mobile-menu').removeClass('open')
    })
  })

  $('#mobile-back-button').off('click')
  if (window.navigator['standalone'] && history.length > 1) {
    $('#mobile-back-button').css('visibility', 'visible')
    $('#mobile-back-button').on('click', ev => {
      ev.preventDefault()
      history.back()
    })
  }
}

$(document).ready(function() {
  init()
})

$(document).on('turbolinks:load', function() {
  init()
})
