import * as $ from 'jquery'

const SAYINGS = [
  "Let's Eat!",
  'Eet Smakelijk!',
  'Bon Appétit!',
  '¡Buen Provecho!',
]

const init = () => {
  $('.sayings').each(function() {
    const elem = $(this)

    if (elem.data('interval')) {
      return
    }

    const copy = SAYINGS.slice()
    let sayings = []

    while (copy.length > 0) {
      const idx = Math.floor(Math.random() * copy.length)
      sayings = sayings.concat(copy.splice(idx, 1))
    }

    elem.data('sayings', sayings)
    elem.data('index', 0)

    const width = elem.innerWidth()
    elem.append($(`<span class="fadein"><p>${sayings[0]}</p></span>`).width(width))

    const id = setInterval(() => {
      const sayings = elem.data('sayings')
      const idx = elem.data('index')
      const nextIdx = (idx >= sayings.length - 1) ? 0 : idx + 1

      elem.data('index', nextIdx)

      const width = elem.innerWidth()
      elem.empty()
      elem.append(
        $(`<span class="fadeout"><p>${sayings[idx]}</p></span>`).width(width)
      )
      elem.append(
        $(`<span class="fadein"><p>${sayings[nextIdx]}</p></span>`).width(width)
      )
    }, 3000)

    elem.data('interval', id)
  })
}

$(document).ready(function() {
  init()
})

$(document).on('turbolinks:load', function() {
  init()
})
