import * as $ from 'jquery'

/**
 * Throws a progress bar into `attachTo'.
 */
export const addBar = (attachTo: any) => {
  const barRoot = $(`<div class="progress-bar" />`).appendTo(attachTo)
  const blocker = $('<div class="blocker" />').appendTo(barRoot)
  const initialWidth = blocker.width()

  barRoot.on('progress', (ev, frac) => {
    const newWidth = initialWidth * (1 - frac)
    blocker.width(newWidth)
  })

  return barRoot
}
