import * as $ from 'jquery'

export function displayStripeError(message) {
  const disp = $('#payment-card-errors')

  if (message) {
    disp.empty()
    disp.append(`<div class="error message">${message}</div>`)
  } else {
    $('#payment-card-errors .message').remove()
  }
}

export function startSubmitPaymentForm() {
  const secret = $('#payment-card-info').data('secret')

  $('#payment-checkout').attr('disabled', true)
  $('#payment-checkout').text('Adding...')

  return secret
}

export function onCardSetupSuccess(paymentMethodId) {
  $('form#payment-method')
    .find('input[name="payment_method[stripe_id]"]')
    .val(paymentMethodId)

  const element = document.querySelector('meta[name="csrf-token"]')
  const authToken = element.getAttribute("content")

  $('form#payment-method')
    .find('input[name="authenticity_token"]')
    .val(authToken)

  $('form#payment-method').submit()
}

export function onCardSetupFailure(message) {
  displayStripeError(message);

  $('#payment-checkout').attr('disabled', false)
  $('#payment-checkout').text('Checkout')
}
