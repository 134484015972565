import * as $ from 'jquery'
import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import * as octicons from '@primer/octicons'

import { addBar } from '../progress-bar'
import { DataUrlDirectUpload } from '../data_url_direct_upload'

class ProgressReporter {
  constructor(bar) {
    this.bar = bar
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const frac = event.loaded / event.total
    this.bar.trigger('progress', frac)
  }

  destroy() {
    this.bar.remove()
  }
}

/**
 * Sets up an input to accept files or take pictures with WebRTC.
 *
 * Resulting structure of the HTML is something like this:
 *
 *  input.pic-upload
 *  div.pic-upload
 *  .pic-upload-button-group
 *    button Picture from Camera/Take Picture/Accept
 *    button Retry
 *    button Cancel
 *  canvas
 *  img
 */
export default class extends Controller {
  connect() {
    if ($(this.element).hasClass('pic-upload')) {
      return
    }

    $(this.element).addClass('pic-upload')

    this.div = document.createElement('div')
    this.div.textContent = 'Upload Picture'
    this.div.className = 'pic-upload'
    this.element.insertAdjacentElement('afterend', this.div)

    this.notice = document.createElement('div')
    this.notice.textContent = 'Starting camera...'
    this.notice.className = 'hidden'
    this.div.insertAdjacentElement('afterend', this.notice)

    this.enableCamera = true
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      this.enableCamera = false
    }

    if (this.enableCamera) {
      this.video = document.createElement('video')
      this.video.className = 'hidden'
      this.notice.insertAdjacentElement('afterend', this.video)

      this.canvas = document.createElement('canvas')
      this.canvas.className = 'hidden'
      this.video.insertAdjacentElement('afterend', this.canvas)

      this.image = document.createElement('img')
      this.image.className = 'hidden'
      this.canvas.insertAdjacentElement('afterend', this.image)

      this.buttonGroup = document.createElement('div')
      this.buttonGroup.className = 'pic-upload-button-group'
      this.image.insertAdjacentElement('afterend', this.buttonGroup)

      this.button = document.createElement('button')
      this.button.innerHTML = `${octicons["device-camera"].toSVG({ height: 20 })} Picture from Camera`
      this.buttonGroup.insertAdjacentElement('beforeend', this.button)
      $(this.button).click(this.initTakePicture)

      this.retryButton = document.createElement('button')
      this.retryButton.textContent = 'Take Another'
      this.retryButton.className = 'hidden'
      this.buttonGroup.insertAdjacentElement('beforeend', this.retryButton)
      $(this.retryButton).click(this.retryPic)

      this.cancelButton = document.createElement('button')
      this.cancelButton.textContent = 'Cancel'
      this.cancelButton.className = 'hidden'
      this.buttonGroup.insertAdjacentElement('beforeend', this.cancelButton)
      $(this.cancelButton).click(this.cancelPicture)
    }

    if ($(this.element).data('signed-id') && $(this.element).data('filename')) {
      this.initUploaded()
    } else {
      this.initEmpty()
    }
  }

  onVideoCanPlay = event => {
    if (this.enableCamera) {
      $(this.canvas).attr('width', this.video.videoWidth)
      $(this.canvas).attr('height', this.video.videoHeight)
      $(this.button).attr('disabled', false)
    }
  }

  initTakePicture = event => {
    event.preventDefault()

    if (!this.enableCamera) {
      return
    }

    $(this.element).hide()
    $(this.div).hide()

    $(this.notice).removeClass('hidden')
    $(this.button).attr('disabled', true)
    $(this.button).html('Take Picture')

    const instance = this

    // TODO: do this once
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(mediaStream => {
        $(instance.video).removeClass('hidden')
        $(instance.video).on('canplay', instance.onVideoCanPlay)
        instance.mediaStream = mediaStream
        instance.video.srcObject = mediaStream
        instance.video.play()

        $(instance.notice).addClass('hidden')
      })
      .catch(err => {
        console.error(err)
      })

    $(this.cancelButton).removeClass('hidden')
    $(this.button).off('click')
    $(this.button).click(this.takePicture)
  }

  takePicture = event => {
    event.preventDefault()

    if (!this.enableCamera) {
      return
    }

    const context = this.canvas.getContext('2d')
    context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)

    const data = this.canvas.toDataURL('image/png')

    $(this.video).addClass('hidden')
    $(this.image).removeClass('hidden')
    this.image.setAttribute('src', data)

    $(this.retryButton).removeClass('hidden')
    $(this.button).text('Use Picture')
    $(this.button).off('click')
    $(this.button).click(event => {
      event.preventDefault()
      this.acceptPicture(data)
    })
  }

  retryPic = event => {
    event.preventDefault()

    if (!this.enableCamera) {
      return
    }

    $(this.image).addClass('hidden')
    $(this.image).attr('src', '')
    $(this.video).removeClass('hidden')
  }

  cancelPicture = event => {
    if (event) {
      event.preventDefault()
    }

    if (!this.enableCamera) {
      return
    }

    const tracks = this.mediaStream.getTracks()
    _.forEach(tracks, track => track.stop())

    $(this.notice).addClass('hidden')
    $(this.video).addClass('hidden')
    $(this.video).off('canplay')

    $(this.image).addClass('hidden')
    $(this.image).attr('src', '')

    $(this.button).off('click')
    $(this.button).click(this.initTakePicture)
    $(this.button).html(`${octicons["device-camera"].toSVG({ height: 20 })} Picture from Camera`)
    $(this.cancelButton).addClass('hidden')
    $(this.retryButton).addClass('hidden')

    $(this.element).show()
    $(this.div).show()
  }

  /**
   * Sets state for a uploader that has a current upload.
   */
  initUploaded = () => {
    this.uploading()

    const signed_id = $(this.element).data('signed-id')
    const filename = $(this.element).data('filename')
    const url = `/images/${signed_id}/${filename}`

    $(this.div).not('.uploaded').addClass('uploaded')

    $(this.div).find('img').remove()
    $(this.div).append(
      `<img
        class="uploaded-picture"
        src="${url}"
      >`
    )

    $(this.div).append(
      `<input
        class="uploaded-picture"
        name="${this.element.name}"
        type="hidden"
        value="${signed_id}"
      >`
    )

    const xButton = $('<span class="x">&times;</span>').appendTo($(this.div))
    xButton.on('click', () => {
      this.initEmpty()
    })

    $(this.button).addClass('hidden')
  }

  /**
   * Sets state for an empty uploader.
   */
  initEmpty = () => {
    $(this.div).children().remove()
    $(this.div).removeClass('uploaded')
    $(this.div).text('Upload Picture')

    $(this.element).prop('disabled', false)
    $(this.element).off('change')
    $(this.element).on('change', (event) => {
      Array.from(this.element.files)
           .forEach(file => this.uploadFile(file))
      $(this.element).val(null)
    })

    $(this.button).removeClass('hidden')
  }

  /**
   * Sets state for an in progress upload.
   */
  uploading = () => {
    $(this.element).prop('disabled', true)
    $(this.div).text('')
  }

  uploadFile = (file) => {
    this.uploading()

    const url = this.element.dataset.directUploadUrl
    const reporter = new ProgressReporter(addBar($(this.div)))
    const upload = new DirectUpload(file, url, reporter)

    const onEmpty = this.initEmpty
    const onUploaded = this.initUploaded
    const input = this.element

    upload.create((error, blob) => {
      reporter.destroy()

      if (error) {
        // TODO: Handle the error, report to some service

        onEmpty()
      } else {
        const fname = file.filename

        $(input).data('signed-id', blob.signed_id)
        $(input).data('filename', fname)
        onUploaded()
      }
    })
  }

  /**
   * Instead of direct upload, use a data URL.
   */
  acceptPicture = data => {
    this.cancelPicture()
    this.uploading()

    const url = $(this.element).data('direct-upload-url')
    const reporter = new ProgressReporter(addBar($(this.div)))
    const tmp = new DataUrlDirectUpload(url, data, reporter)

    const onEmpty = this.initEmpty
    const onUploaded = this.initUploaded
    const input = this.element

    tmp.upload().then(res => {
      $(input).data('signed-id', res.signed_id)
      $(input).data('filename', res.filename)
      onUploaded()
    }).catch(err => {
      console.error(err)
      onEmpty()
    })
  }
}
