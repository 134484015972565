import * as $ from 'jquery'

const init = () => {
  $('span.close').off('click')
  $('span.close').on('click', function() {
    $(this).parent().parent().removeClass('active')
  })

  $('.modal').off('click')
  $('.modal').on('click', function() {
    $(this).removeClass('active')
  })

  $('.modal .content').on('click', ev => {
    ev.preventDefault()
    ev.stopPropagation()
  })

  $('img.expandable.thumbnail').off('click')
  $('img.expandable.thumbnail').on('click', function() {
    const modalId = $(this).data('modal')
    $(`#${modalId}`).addClass('active')
  })
}

$(document).ready(function() {
  init()
})

$(document).on('turbolinks:load', function() {
  init()
})
