// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);

require("../dinner-spinner")
require("../expandable-thumbnail")
require("../mobile-menu")
require("../recipes")
require("../recipe-show")
require("../sayings")

var payments = require("../payments");
window.displayStripeError = payments.displayStripeError;
window.onCardSetupSuccess = payments.onCardSetupSuccess;
window.startSubmitPaymentForm = payments.startSubmitPaymentForm;
window.onCardSetupFailure = payments.onCardSetupFailure;

window.copy_profile_link = require("../copy-profile-link").copy_profile_link;

require('../service-worker-registration')

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (process.env.SENTRY_CURRENT_ENV) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN_JS,
    environment: process.env.SENTRY_CURRENT_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  })
} else {
  console.log('Not enabling sentry.io')
}
