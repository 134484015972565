import * as $ from 'jquery'
import * as _ from 'lodash'
import * as octicons from '@primer/octicons'

$(document).on('turbolinks:load', () => {
  $('.dinner-spinner button').off('click')

  $('.dinner-spinner button').on('click', (ev) => {
    ev.preventDefault()

    $('.dinner-spinner-hide-after').hide()
    $('.dinner-spinner-target')
      .html(octicons.hourglass.toSVG({ height: 24 }))

    const path = $('.dinner-spinner').data('path') || '/public_recipe'
    fetch(path).then(res => {
      return res.text()
    }).then(res => {
      $('.dinner-spinner-target').html(res)
    })
  })
})
