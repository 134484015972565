import * as $ from 'jquery'

export function copy_profile_link() {
  const link = $('#copy-link').data('profile')

  navigator.clipboard.writeText(link)
  $('#copy-link').hide()
  $('#copy-link-copied').show()

  setTimeout(() => {
    $('#copy-link-copied').hide()
    $('#copy-link').show()
  }, 2000)
}
