import * as $ from 'jquery'
import NoSleep from 'nosleep.js'

const sizeKitchenMode = () => {
  const screenHeight = $(window).height()
  const topPadding = parseInt($('main.container').css('padding-bottom'))
  const bottomPadding = parseInt($('main.container').css('padding-bottom'))

  const remove = topPadding + bottomPadding + 4
  $('.recipe.kitchen-mode').height(screenHeight - remove)
}

const init = () => {
  const noSleep = new NoSleep()

  $('#enter-kitchen-mode').off('click')
  $('#enter-kitchen-mode').click(() => {
    sizeKitchenMode()
    $('.recipe.normal-mode').addClass('hidden')
    $('.recipe.kitchen-mode').removeClass('hidden')
    $('nav').addClass('hidden')
    $('footer').addClass('hidden')
    noSleep.enable()
  });

  $('#enter-normal-mode').off('click')
  $('#enter-normal-mode').click(() => {
    noSleep.disable()
    $('.recipe.kitchen-mode').addClass('hidden')
    $('.recipe.normal-mode').removeClass('hidden')
    $('nav').removeClass('hidden')
    $('footer').removeClass('hidden')
  });
}

$(document).ready(function() {
  init()
})

$(document).on('turbolinks:load', function() {
  init()
})
