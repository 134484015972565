import SparkMD5 from "spark-md5"
import * as _ from "lodash"

export class DataUrlDirectUpload {
  constructor(url, data, reporter) {
    this.url = url
    this.data = data
    this.reporter = reporter
  }

  upload() {
    return this.createBlob().then(res => {
      return res.json()
    }).then(data => {
      return this.uploadBlob(data)
    }).then(() => {
      return this.resData
    })
  }

  createBlob() {
    const filename = _.replace(
      _.replace((new Date).toISOString(), /:/g, '-'),
      /\..*$/,
      ''
    )

    const body = {
      blob: {
        filename: `${filename}.png`,
        content_type: 'image/png',
        byte_size: this.blob().size,
        checksum: this.checksum(),
      }
    }

    return fetch(this.url, {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken(),
      },
      body: JSON.stringify(body),
    })
  }

  uploadBlob(response) {
    this.resData = {
      filename: response.filename,
      signed_id: response.signed_id,
    }

    const { url, headers } = response.direct_upload

    const xhr = new XMLHttpRequest
    xhr.open("PUT", url, true)
    xhr.responseType = "text"
    xhr.upload.addEventListener('progress',
      this.reporter.directUploadDidProgress.bind(this.reporter))

    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key])
    }

    const promise = new Promise((resolve, reject) => {
      xhr.addEventListener("load", event => {
        const { status, response } = xhr
        if (status >= 200 && status < 300) {
          resolve(response)
        } else {
          reject(response)
        }
      })

      xhr.addEventListener("error", event => {
        reject(event)
      })
    })

    xhr.send(this.blob())
    return promise
  }

  getCsrfToken() {
    const element = document.querySelector('meta[name="csrf-token"]')
    if (element) {
      return element.getAttribute("content")
    }
  }

  blob() {
    if (this._blob) {
      return this._blob;
    }

    const tmp = atob(_.replace(this.data, /^data:image\/png;base64,/, ''))

    this._blobRaw = new Uint8Array(tmp.length)
    for (var i = 0; i < tmp.length; i++) {
      this._blobRaw[i] = tmp.charCodeAt(i)
    }

    this._blob = new Blob([this._blobRaw], { type: 'image/png' })
    return this._blob
  }

  checksum() {
    const md5Buffer = new SparkMD5.ArrayBuffer
    md5Buffer.append(this._blobRaw.buffer)
    return btoa(md5Buffer.end(true))
  }
}
